/*!
 * Glance Digital Custom JavaScript Programing
 * lance@glance.ca
 *
 */

jQuery(document).ready(function($) {
  // SETTINGS
  // ------------------

  // Velocty Easing
  // ------

  $.Velocity.defaults.easing = "easeInOutQuart";

  // PLUGINS
  // ------------------

  // Top Header Nav

  if ($(".site-header").length) {
    $(".site-header").switchNav();
  }

  if ($(".add-panel").length) {
    $(".add-panel").addMedia();
  }

  // Anchor Link Scroll

  $("a[href^='#']").scrollto({ offset_lg: 72, offset_sm: 48 });

  // Ajax load more

  if ($("#gallery-grid").length) {
    $("#gallery-grid").ajaxLoad({
      url: "/",
      post_type: "post",
      template: "parts/loop-archive.php"
    });

    $("#gallery-grid").galleryPopup();
  }

  // Video Player

  if ($(".watch").length) {
    $(".watch").videoPlayer();
  }

  // Init Breakpoint Listeners
  // ------------------

  $(this).breakpoints();
});

// ------------------------------------------------------------------------------------------------------------------------------------------------
// PLUGINS

(function($) {
  // Gravity Forms image upload : use custom 'delete' icon

  // gform.addFilter('gform_file_upload_markup', function (html, file, up, strings, imagesUrl) {
  //   var formId = up.settings.multipart_params.form_id,
  //   fieldId = up.settings.multipart_params.field_id;
  //   html = '<strong>' + file.name + "</strong> <span class='gform_delete' onclick='gformDeleteUploadedFile(" + formId + "," + fieldId + ", this);' title='" + strings.delete_file + "' ></span>";

  // return html;
  // });

  // Top Navigation : toggle mobile and desktop
  // ------

  $.fn.switchNav = function(options) {
    var settings = $.extend(
      {
        breakpoint: "atleast_large"
      },
      options
    );

    var $top = this;
    var nav_desk = $top.desktopMenu();
    var nav_mobile = null;

    function switchNav(obj, media) {
      // Set Desktop Nav
      if (media[settings.breakpoint] || media.fallback) {
        if (nav_mobile != null) {
          nav_mobile.kill();
          nav_mobile = null;
        }

        // Set Mobile Nav
      } else {
        if (nav_mobile == null) {
          nav_mobile = $top.mobileMenu();
        }
      }
    }
    $.subscribe("breakpoints", switchNav);
  };

  // Mobile Menu

  $.fn.mobileMenu = function() {
    var $body = $("body");
    // var $container = this;
    var $nav = this.find("#primary");
    var $primaryDropdown = this.find(".hamburger");

    $nav.velocity("slideUp", { duration: 0, display: "none" });

    $primaryDropdown.on("click", function(e) {
      e.preventDefault();
      if ($body.hasClass("header-open")) {
        $primaryDropdown.removeClass("is-active");
        $body.removeClass("header-open");
        $nav.velocity("slideUp", { duration: 600, display: "none" });
      } else {
        $primaryDropdown.addClass("is-active");
        $body.addClass("header-open");
        $nav.velocity("slideDown", { duration: 600, display: "block" });
      }
    });

    return {
      kill: function() {
        $primaryDropdown.off("click").removeClass("is-active");
        $body.removeClass("header-open");
        $nav.velocity("slideDown", {
          duration: 0,
          display: "block",
          complete: function() {
            $nav.attr("style", "");
          }
        });
      }
    };
  };

  // Desktop Menu

  $.fn.desktopMenu = function(options) {
    var settings = $.extend(
      {
        trigger_offset: 33
      },
      options
    );
    var $body = $("body");
    // var $container = this;
    var $window = $(window);
    var scrollTop = $window.scrollTop();
    var min = false;

    function scrollState() {
      scrollTop = $window.scrollTop();

      // Minimized Top State
      if (scrollTop >= settings.trigger_offset && !min) {
        min = true;
        $body.addClass("header-minimize");

        // Full Top State
      } else if (scrollTop < settings.trigger_offset && min) {
        min = false;
        $body.removeClass("header-minimize");
      }
    }

    $window.on("scroll.menustate", scrollState);
    scrollState();

    // Kill desktop functionality
    // ------

    return {
      kill: function() {
        $body.removeClass("header-minimize");
        $window.off("scroll.menustate");
      }
    };
  };

  // Anchor scroll
  // ------

  $.fn.scrollto = function(options) {
    var settings = $.extend(
      {
        offset_lg: 0,
        offset_sm: 0,
        exclude: "tabbed-link"
      },
      options
    );

    var offset = settings.offset_lg;

    $.subscribe("breakpoints", function(obj, media) {
      offset = media.small ? settings.offset_sm : settings.offset_lg;
    });

    this.on("click", function(event) {
      var id = $(this)
        .attr("href")
        .split("#")[1];

      if ($("#" + id).length && !$(this).hasClass(settings.exclude)) {
        event.preventDefault();

        var $el = $("#" + id);
        var top = id == "top" ? $el.offset().top : $el.offset().top - offset;
        $("html").velocity("scroll", { duration: 900, offset: top });
      }
    });

    return this;
  };

  // Ajax load more posts
  // ------

  $.fn.ajaxLoad = function(options) {
    var settings = $.extend(
      {
        url: "/",
        post_type: "post",
        page_action: "posts_load",
        paginate: "#paginateNav",
        post: ".ajax-post",
        posts_per_page: "posts_per_page",
        filter: ".filter .category",
        template: "parts/loop-archive.php",
        updateBrowserState: false,
        filterPrefix: "",
        language: false
      },
      options
    );

    var $container = this;
    var $paginateNav = $container.next(settings.paginate);
    var pageIsLoading = false;
    var postsPerPage = this.data(settings.posts_per_page);
    var lang = settings.language ? this.data("lang") : false;
    var $filters = $(settings.filter);

    var filter = new Array();
    var filter_str = "";

    $paginateNav.on("click.ajaxload", function(e) {
      e.preventDefault();
      updateFilter();
      ajaxLoad("paginate");
    });

    $filters.find("li").on("click", function() {
      var $li = $(this);
      $li
        .addClass("selected")
        .siblings()
        .removeClass("selected");
      updateFilter();
      ajaxLoad("filter");
    });

    function updateFilter() {
      filter = [];
      $filters.each(function() {
        var $this = $(this);
        var taxonomy = $this.data("taxonomy");
        $this.find("li").each(function() {
          var $li = $(this);
          if ($li.hasClass("selected") && $li.data("filter") != "all") {
            filter.push(taxonomy + ":" + $li.data("filter"));
          }
        });
      });

      filter_str = filter.length ? filter.join(";") : "";
    }

    function ajaxLoad(action) {
      if (!pageIsLoading) {
        pageIsLoading = true;

        $container.addClass("loading " + action);
        $paginateNav.addClass("loading " + action);

        var offset =
          action == "paginate" ? $container.find(settings.post).length : 0;

        var data = {
          action: settings.page_action,
          type: settings.post_type,
          template: settings.template,
          offset: offset,
          postsPerPage: postsPerPage,
          filter: filter_str
        };

        if (lang) {
          data.lang = lang;
        }

        if (action == "filter") {
          $paginateNav.show();
        }

        $.ajax({
          url: glance.ajaxUrl,
          type: "post",
          data: data,
          success: function(result) {
            var $result = $(result);
            var numResults = $result.length;
            var maxPages = $result.first().data("max");
            var hidePaginate =
              numResults < postsPerPage ||
              !(maxPages * postsPerPage > offset + numResults);

            if (action == "paginate") {
              $result.appendTo($container);
            } else {
              $container.html($result);
            }

            if (hidePaginate) $paginateNav.hide();
            pageIsLoading = false;
            $container.removeClass("loading " + action);
            $paginateNav.removeClass("loading " + action);

            if (settings.updateBrowserState) {
              if (filter_str == "") {
                window.history.pushState("filter", "Filter", location.pathname);
              } else {
                var filters = filter_str.split(";");
                var query = "?";
                $.each(filters, function(i, pair) {
                  var ar = pair.split(":");
                  var label = ar[0].split(settings.filterPrefix);
                  query += label[1] + "=" + ar[1] + "&";
                });
                query = query.slice(0, -1);
                window.history.pushState(
                  "filter",
                  "Filter",
                  location.pathname + query
                );
              }
            }
          }
        });
      }
    }

    window.addEventListener("popstate", function(event) {
      // console.log(event);
      if (event.state == "filter") {
        window.location.reload();
      }
    });

    return this;
  };

  $.fn.addMedia = function() {
    var $panel = this;
    var $tagline = $(".tagline");

    $(window).on("click", function() {
      if ($panel.hasClass("on")) {
        toggleOptions();
      }
    });

    this.find(".add").on("click", function(event) {
      event.stopPropagation();
      toggleOptions();
    });

    function toggleOptions() {
      if ($panel.hasClass("on")) {
        $panel.removeClass("on");
        $tagline.removeClass("off");
      } else {
        $panel.addClass("on");
        $tagline.addClass("off");
      }
    }
  };

  // Gallery Popup

  $.fn.galleryPopup = function() {

    this.on("click", "a", function(e) {
      e.preventDefault();
      $(this)
        .closest("article")
        .addClass("zoom");

    }).on("click", ".close, .bg", function(e) {
      e.preventDefault();
      $(this)
        .closest("article")
        .removeClass("zoom");
    });

  };

  // HTML 5 Video Player

  $.fn.videoPlayer = function() {
    const $container = this;
    const $video = $container.find('video');
    const $toggle = $container.find('.playpause-button');

    $toggle.on('click', function() {
      playPause($video[0]);
    });

    function playPause (video) {
      if (video.paused) {
        video.play(); 
        $container.addClass('playing');
      } else {
        video.pause();
        $container.removeClass('playing');
      }
    }
  };
  
})(jQuery);
